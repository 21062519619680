// Imports
          import { Link } from 'react-router-dom'
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component124Page() {
const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_1_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_1_onClick ")
                }  
              };

const bloc0_2_onClick = async (e) => {
                try{ 
                      navigate('/project');
                }
                catch(e){
                  console.log("Error bloc0_2_onClick ")
                }  
              };

const bloc0_3_onClick = async (e) => {
                try{ 
                      navigate('/contact');
                }
                catch(e){
                  console.log("Error bloc0_3_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "p-2 flex justify-end text-black font-semibold place-content-end text-end font-app-2 mt-10"}     > <div id='bloc0_0'  className={ "p-2 "}     > </div>
          <Link    to="/home"><div id='bloc0_1'  className={ "p-2 appbutton"}    onClick = { bloc0_1_onClick } >{`Accueil`} </div></Link>
          <Link    to="/project"><div id='bloc0_2'  className={ "p-2 appbutton"}    onClick = { bloc0_2_onClick } >{`Project`} </div></Link>
          <Link    to="/contact"><div id='bloc0_3'  className={ "p-2 appbutton"}    onClick = { bloc0_3_onClick } >{`Contact`} </div></Link></div><div id='bloc1'  className={ "text-black font-bold px-2 bg-white font-app-2 text-2xl md:text-4xl lg:text-6xl py-20"}     > <div id='bloc1_0'  className={ "text-start"}     >{`Bonjour vous !`} </div>
          <div id='bloc1_1'  className={ "flex"}     > <div id='bloc1_1_0'       >{`Je suis`} </div>
            <div id='bloc1_1_1'  className={ "pl-1 text-primary_color"}     >{`Marie`} </div>
            <div id='bloc1_1_2'       >{`,`} </div></div>
          <div id='bloc1_2'  className={ "flex"}     > <div id='bloc1_2_0'       >{`Développeuse`} </div>
            <div id='bloc1_2_1'  className={ "pl-1 text-primary_color"}     >{`Web`} </div>
            <div id='bloc1_2_2'  className={ "pl-1"}     >{`&`} </div></div>
          <div id='bloc1_3'  className={ "flex"}     > <div id='bloc1_3_0'       > </div>
            <div id='bloc1_3_1'  className={ "pl-1 text-primary_color"}     >{`UI/UX`} </div>
            <div id='bloc1_3_2'  className={ "pl-1"}     >{`designer.`} </div></div></div><div id='bloc3'  className={ "rounded-lg px-2 mb-2 font-app-2 mx-2 pb-8"}     > <div id='bloc3_0'  className={ "text-start font-bold text-xl text-black py-4"}     >{`MES PROJETS`} </div>
          <div id='bloc3_1'  className={ "flex"}     > <div id='bloc3_1_0x0'  className={ "p-2 w-1/3"}     > <div id='bloc3_1_0x0_0'  className={ "border-2 border-indigo-700 rounded-lg pb-2"}     > <img  id='bloc3_1_0x0_0_0'  className={ "w-full rounded-t-lg h-64   object-cover"}  src="https://fs.appisyou.com/users/17/heros1732010747684.png"   alt="undefined" />
                <div id='bloc3_1_0x0_0_1'  className={ "text-xs text-center"}     >{`Heros League`} </div></div></div>
            <div id='bloc3_1_1x0'  className={ "p-2 w-1/3"}     > <div id='bloc3_1_1x0_0'  className={ "flex flex-row"}     > <div id='bloc3_1_1x0_0_0'  className={ "border-2 border-indigo-700 rounded-lg pb-2"}     > <img  id='bloc3_1_1x0_0_0_0'  className={ "w-full rounded-t-lg h-64   object-cover"}  src="https://fs.appisyou.com/users/17/template_493_thumb.png"   alt="undefined" />
                  <div id='bloc3_1_1x0_0_0_1'  className={ "text-xs text-center"}     >{`App Stripe`} </div></div></div></div>
            <div id='bloc3_1_2'  className={ "p-2 w-1/3"}     > <div id='bloc3_1_2_0'  className={ "border-x-2 border-indigo-700 border-t-2 rounded-lg"}     > <img  id='bloc3_1_2_0_0'  className={ "appbutton rounded-t-lg h-32   object-cover"}  src="https://fs.appisyou.com/users/17/ocoffee1732009749793.png"   alt="undefined" /></div>
              <div id='bloc3_1_2_1'  className={ "border-x-2 border-indigo-700 border-b-2 pb-2 rounded-lg"}     > <img  id='bloc3_1_2_1_0'  className={ "h-32   object-cover"}  src="https://fs.appisyou.com/users/17/ocoffee21732011133983.png"   alt="undefined" />
                <div id='bloc3_1_2_1_1'  className={ "text-xs text-center"}     >{`Ocoffee`} </div></div></div></div></div><div id='bloc4'  className={ "mt-20 font-app-2 pb-10 text-black"}     > <div id='bloc4_0'  className={ "text-start font-bold text-xl pb-4 p-4"}     >{`MES HARDSKILLS`} </div>
          <div id='bloc4_1'  className={ "gap-2 p-2 font-medium flex  flex-col  md:flex-row"}     > <div id='bloc4_1_0'  className={ "border-2 border-indigo-700 rounded-lg text-center h-auto"}     > <div id='bloc4_1_0_0'  className={ "mb-2 rounded-t-lg bg-indigo-700 text-white font-semibold p-2"}     >{`FRONT-END`} </div>
              <div id='bloc4_1_0_1'  className={ "pt-2"}     >{`HTML - CSS`} </div>
              <div id='bloc4_1_0_2'       >{`Tailwind`} </div>
              <div id='bloc4_1_0_3'       >{`Javascript`} </div>
              <div id='bloc4_1_0_4'  className={ "pb-2"}     >{`React`} </div></div>
            <div id='bloc4_1_1'  className={ "border-2 border-indigo-700 rounded-lg text-center h-auto"}     > <div id='bloc4_1_1_0'  className={ "mb-2 rounded-t-lg bg-indigo-700 text-white font-semibold p-2"}     >{`BACK-END`} </div>
              <div id='bloc4_1_1_1'  className={ "pt-2"}     >{`NodeJs`} </div>
              <div id='bloc4_1_1_2'       >{`Express`} </div>
              <div id='bloc4_1_1_3'       >{`EJS`} </div>
              <div id='bloc4_1_1_4'       >{`Sequelize`} </div>
              <div id='bloc4_1_1_5'  className={ "pb-2"}     >{`PostgreSQL`} </div></div>
            <div id='bloc4_1_2'  className={ "border-2 border-indigo-700 rounded-lg text-center h-auto"}     > <div id='bloc4_1_2_0'  className={ "mb-2 rounded-t-lg bg-indigo-700 text-white font-semibold p-2"}     >{`DESIGN`} </div>
              <div id='bloc4_1_2_1'  className={ "pt-2"}     >{`Figma`} </div>
              <div id='bloc4_1_2_2'  className={ "pb-2"}     >{`Canva`} </div></div></div></div><div id='bloc5'  className={ "mt-20 rounded-lg m-2 font-app-2"}     > <div id='bloc5_0'  className={ "text-start font-bold text-xl text-black py-4 px-2"}     >{`MON PARCOURS`} </div>
          <div id='bloc5_1'  className={ "gap-2 text-center font-medium flex  flex-col  md:flex-row"}     > <div id='bloc5_1_0'  className={ "h-auto border-2 border-indigo-700 rounded-lg"}     > <div id='bloc5_1_0_0'  className={ "mb-2 rounded-t-lg bg-indigo-700 text-white font-semibold p-2"}     >{`FORMATION DWWM`} </div>
              <div id='bloc5_1_0_1'  className={ "p-1 py-4"}     >{`Formation Développeur web et web mobile chez O’Clock`} </div></div>
            <div id='bloc5_1_1'  className={ "h-auto border-2 border-indigo-700 rounded-lg"}     > <div id='bloc5_1_1_0'  className={ "mb-2 rounded-t-lg bg-indigo-700 text-white font-semibold p-2"}     >{`CERTIFICATION OPQUAST`} </div>
              <div id='bloc5_1_1_1'  className={ "p-1 py-4"}     >{`Socle de règles, de compétences et de vocabulaire multidisciplinaires dans les métiers du web.`} </div></div>
            <div id='bloc5_1_2'  className={ "h-auto border-2 border-indigo-700 rounded-lg"}     > <div id='bloc5_1_2_0'  className={ "mb-2 rounded-t-lg bg-indigo-700 text-white font-semibold p-2"}     >{`STAGE DEVELOPPEMENT WEB`} </div>
              <div id='bloc5_1_2_1'  className={ "p-1 py-4"}     >{`Stage en développement web chez AppIsYou  
      `} </div></div>
            <div id='bloc5_1_3'  className={ "h-auto border-2 border-indigo-700 rounded-lg"}     > <div id='bloc5_1_3_0'  className={ "mb-2 rounded-t-lg bg-indigo-700 text-white font-semibold p-2"}     >{`CRÉATION MICRO-ENTREPRISE`} </div>
              <div id='bloc5_1_3_1'  className={ "p-1 py-4"}     >{`Lancement de mon activité en freelance
      
      `} </div></div></div></div><div id='bloc6'  className={ "p-2 mt-20 rounded-lg font-app-2"}     > <div id='bloc6_0'  className={ "text-start font-bold text-xl text-black pb-4"}     >{`CONTACTEZ MOI`} </div>
          <div id='bloc6_1'  className={ "gap-2 flex flex-col md:flex-row justify-center"}     > <div id='bloc6_1_0x0'  className={ "p-2 h-28 border-2 border-indigo-700 rounded-lg bg-violet-50 text-black place-content-center flex flex-col items-center w-5/6"}     > <div id='bloc6_1_0x0_0'  className={ "pt-2 font-semibold"}     >{`Linkedin`} </div>
              <FontAwesomeIcon  id='bloc6_1_0x0_1'  icon={ fa.faExternalLinkAlt}      className={ "h-6 w-6"}  />
              <div id='bloc6_1_0x0_2'  className={ "p-2 appbutton"}     >{`Par ici ->`} </div></div>
            <div id='bloc6_1_1x0'  className={ "p-2 h-28 border-2 border-indigo-700 rounded-lg text-black bg-violet-50 place-content-center flex flex-col items-center w-5/6"}     > <div id='bloc6_1_1x0_0'  className={ "pt-2 font-semibold"}     >{`EMAIL`} </div>
              <FontAwesomeIcon  id='bloc6_1_1x0_1'  icon={ fa.faMailBulk}      className={ "h-8 w-8"}  />
              <div id='bloc6_1_1x0_2'  className={ "appbutton"}     >{`Par là ->`} </div></div></div></div><div id='bloc7'  className={ "w-full bg-white font-app-2 pb-10"}     > <div id='bloc7_0'  className={ "mt-20"}     > <div id='bloc7_0_0'  className={ "p-2 text-xl text-black font-semibold text-indigo-700 text-center"}     >{`By Marie.`} </div>
            <div id='bloc7_0_1'  className={ "p-2 flex justify-center text-black gap-5"}     > <div id='bloc7_0_1_0'  className={ "p-2 "}     >{`A propos`} </div>
              <div id='bloc7_0_1_1'  className={ "p-2 "}     >{`Contact`} </div></div>
            <div id='bloc7_0_2'  className={ "text-black text-center"}     >{` © MarieDev&Design.com - All rights reserved`} </div></div></div></>
  
}

export default Component124Page;
