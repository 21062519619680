// Imports
          import { Link } from 'react-router-dom'
import InputText from "../../../../components/inputs/InputText";
import InputDate from "../../../../components/inputs/imputDate/InputDate";
import InputTextArea from "../../../../components/inputs/InputTextArea";
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component125Page() {
const navigate = useNavigate()


const [content,setContent]= useState( {} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_1_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_1_onClick ")
                }  
              };

const bloc0_2_onClick = async (e) => {
                try{ 
                      navigate('/project');
                }
                catch(e){
                  console.log("Error bloc0_2_onClick ")
                }  
              };

const bloc0_3_onClick = async (e) => {
                try{ 
                      navigate('/contact');
                }
                catch(e){
                  console.log("Error bloc0_3_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "p-2 flex justify-end text-black font-semibold place-content-end text-end font-app-2 mt-10"}     > <div id='bloc0_0'  className={ "p-2 "}     > </div>
          <Link    to="/home"><div id='bloc0_1'  className={ "p-2 appbutton"}    onClick = { bloc0_1_onClick } >{`Accueil`} </div></Link>
          <Link    to="/project"><div id='bloc0_2'  className={ "p-2 appbutton"}    onClick = { bloc0_2_onClick } >{`Project`} </div></Link>
          <Link    to="/contact"><div id='bloc0_3'  className={ "p-2 appbutton"}    onClick = { bloc0_3_onClick } >{`Contact`} </div></Link></div><div id='bloc1'  className={ "bg-white w-screen flex flex-col items-center justify-center"}     > <div id='bloc1_0'  className={ "p-2 bg-white w-screen p-2 font-app-2"}     > <div id='bloc1_0_0'  className={ "p-2 text-start font-bold text-xl"}     >{`Formulaire de contact`} </div>
            <InputText id={"input_bloc1_0_1"} label={"Nom"} onChange={ (value)=>{
    
    
                      setInternal( "Nom",value)
                   } } value={content[ "Nom"]??''} className={" p-2 "} placeholder={"Nom"} />
            <InputDate id={"input_bloc1_0_2"} label={"Date"} onChange={ (value)=>{
    
    
                      setInternal( "Date",value)
                   } } value={content[ "Date"]??''} className={" p-2 "} placeholder={"Date"} />
            <InputText id={"input_bloc1_0_3"} label={"Prénom"} onChange={ (value)=>{
    
    
                      setInternal( "Prénom",value)
                   } } value={content[ "Prénom"]??''} className={" p-2 "} placeholder={"Prénom"} />
            <InputText id={"input_bloc1_0_4"} label={"Adresse Mail"} onChange={ (value)=>{
    
    
                      setInternal( "Adresse Mail",value)
                   } } value={content[ "Adresse Mail"]??''} className={" p-2 "} placeholder={"Adresse Mail"} />
            <InputText id={"input_bloc1_0_5"} label={"Sujet"} onChange={ (value)=>{
    
    
                      setInternal( "Sujet",value)
                   } } value={content[ "Sujet"]??''} className={" p-2 "} placeholder={"Sujet"} />
            <InputTextArea id={"input_bloc1_0_6"} label={"Message"} onChange={ (value)=>{
    
    
                      setInternal( "Message",value)
                   } } value={content[ "Message"]??''} className={" p-2 "} placeholder={"Message"}  />
            <div id='bloc1_0_7'  className={ "p-2 flex flex-row justify-end"}     > <div id='bloc1_0_7_0'  className={ "p-2 rounded-full text-white bg-primary_color"}     >{`Envoyer message`} </div></div>
            <div id='bloc1_0_8'  className={ " p-2 "}     > </div>
            <div id='bloc1_0_9'  className={ " p-2 "}     > </div></div></div><div id='bloc2'  className={ "p-2 mt-20 rounded-lg font-app-2"}     > <div id='bloc2_0'  className={ "text-start font-bold text-xl text-black pb-4"}     >{`CONTACTEZ MOI`} </div>
          <div id='bloc2_1'  className={ "gap-2 flex flex-col md:flex-row justify-center"}     > <div id='bloc2_1_0x0'  className={ "p-2 h-28 border-2 border-indigo-700 rounded-lg bg-violet-50 text-black place-content-center flex flex-col items-center w-5/6"}     > <div id='bloc2_1_0x0_0'  className={ "pt-2 font-semibold"}     >{`Linkedin`} </div>
              <FontAwesomeIcon  id='bloc2_1_0x0_1'  icon={ fa.faExternalLinkAlt}      className={ "h-6 w-6"}  />
              <div id='bloc2_1_0x0_2'  className={ "p-2 appbutton"}     >{`Par ici ->`} </div></div>
            <div id='bloc2_1_1x0'  className={ "p-2 h-28 border-2 border-indigo-700 rounded-lg text-black bg-violet-50 place-content-center flex flex-col items-center w-5/6"}     > <div id='bloc2_1_1x0_0'  className={ "pt-2 font-semibold"}     >{`EMAIL`} </div>
              <FontAwesomeIcon  id='bloc2_1_1x0_1'  icon={ fa.faMailBulk}      className={ "h-8 w-8"}  />
              <div id='bloc2_1_1x0_2'  className={ "appbutton"}     >{`Par là ->`} </div></div></div></div><div id='bloc3'  className={ "w-full bg-white font-app-2 pb-10"}     > <div id='bloc3_0'  className={ "mt-20"}     > <div id='bloc3_0_0'  className={ "p-2 text-xl text-black font-semibold text-indigo-700 text-center"}     >{`By Marie.`} </div>
            <div id='bloc3_0_1'  className={ "p-2 flex justify-center text-black gap-5"}     > <div id='bloc3_0_1_0'  className={ "p-2 "}     >{`A propos`} </div>
              <div id='bloc3_0_1_1'  className={ "p-2 "}     >{`Contact`} </div></div>
            <div id='bloc3_0_2'  className={ "text-black text-center"}     >{` © MarieDev&Design.com - All rights reserved`} </div></div></div></>
  
}

export default Component125Page;
